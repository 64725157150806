<template>
    <footer>
        <div class="content">
            <!-- <img
                    style="margin-left: -50px"
                    src="../assets/images/8-1.png"
                    alt=""
                /> -->
            <div class="company">
                <!-- <img src="../assets/images/huaihu.png" alt="" /> -->
                <img src="../assets/images/a821f2aa7aee002e7d29775a7d64adc.png" alt="" />
                <!-- <p>杭州骊龙网络有限公司 版权所有</p> -->
            </div>
            <div class="line"></div>
            <div class="text">
                <p>
                    <a href="http://cyberpolice.mps.gov.cn/wfjb/" target="_bank">中国互联网举报中心</a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="http://cyberpolice.mps.gov.cn/wfjb/" target="_bank">网络违法举报中心</a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href="http://cyberpolice.mps.gov.cn/wfjb/" target="_bank">垃圾信息举报中心</a>
                </p>
                <p @click="toGongXin" style="cursor: pointer">
                    浙网文(2017)4460-321号&nbsp;&nbsp;&nbsp;&nbsp;浙ICP备19047526号
                </p>
                <p>浙公网安备&nbsp;&nbsp;33011002013461号</p>
                <p>联系方式：18968060998</p>
            </div>
            <div class="line"></div>
            <div class="game_notice">
                <p style="font-weight: 600">健康游戏忠告：</p>
                <p>抵制不良游戏&nbsp;&nbsp;拒绝盗版游戏</p>
                <p>注意自我保护&nbsp;&nbsp;谨防上当受骗</p>
                <p>适度游戏益脑&nbsp;&nbsp;成谜游戏伤身</p>
                <p>合理安排时间&nbsp;&nbsp;享受健康生活</p>
            </div>
        </div>
    </footer>
</template>

<script setup>
const toGongXin = () => {
    window.open(`https://beian.miit.gov.cn`);
};
</script>

<style lang="less" scoped>
footer {
    width: 100%;
    height: 300px;
    background-color: #333;
    color: white;

    .content {
        width: 1300px;
        padding-top: 60px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .company {
            width: 300px;

            img {
                width: 120px;
            }
        }

        .line {
            width: 1px;
            height: 120px;
            background-color: #868686;
            margin-top: 20px;
        }

        .text {
            p {
                margin-bottom: 20px;
                font-weight: 700;

                a {
                    color: white;
                }
            }
        }
    }

    .game_notice {
        p {
            margin-bottom: 10px;
        }
    }
}
</style>